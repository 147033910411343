import { defineStore } from 'pinia';

// 宣告、並匯出user的State
export interface iPatientState {
    patient_id: number
    name: string
    identification: string // 身分證字號
    age: number
    section_note: string
    next_appointment: string // 下次回診時間
    doctor: string
    text_record: string // 廔管建置狀況
    survey: string // 問卷
}

const StateDefault: iPatientState = {
    patient_id: 0,
    name: '',
    identification: '', // 身分證字號
    age: 0,
    section_note: '',
    next_appointment: '', // 下次回診時間
    doctor: '',
    text_record: '',
    survey: ''
};

// action
const usePatientStore = defineStore('usePatientStore', () => {
    const patientState: Ref<iPatientState> = ref({ ...StateDefault });

    // 登入
    const setPatient = (data: string) => {
        // 記錄登入者資訊
        patientState.value = JSON.parse(data);
    };

    const resetPatient = () => {
        // [-]解構賦值的話  pinia 工具會有顯示問題 assign 則正常工作 雖然最終結果相同
        // patientState = {...StateDefault};
        Object.assign(patientState, StateDefault);
    };

    const updatePatient = (data: iPatientState) => {
        patientState.value = Object.assign(patientState.value, data);

        // patientState.value.section_note = data.section_note;
        // patientState.value.next_appointment = data.next_appointment;
        // patientState.value.survey = data.survey;
        // patientState.value.text_record = data.text_record;
    };

    return {
        patientState,
        setPatient,
        resetPatient,
        updatePatient
    };
});

export default usePatientStore;
